import React from 'react';
import ProjectView from '../components/project/projectView';


const Project: React.FC = () => {

    return(
        <ProjectView/>
    )
};

export default Project;
