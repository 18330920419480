import React from 'react';

const CGV = () => {
  return (
   
  <div className="container mx-auto p-6">
    <h1 className="text-3xl font-bold mb-6">Conditions Générales de Vente (CGV)</h1>
    <article className="prose">
      <h2 className="font-bold">Article 1 : Champ d'application</h2>
      <p className="ml-2">Les présentes Conditions Générales de Vente (CGV) s'appliquent à toutes les prestations en ligne fournies par l'entreprise Daara Academy, immatriculée au Sénégal sous le numéro SN DKR 2023 A 14219. Elles régissent les relations contractuelles entre Daara Academy et ses clients.</p>

      <h2 className="font-bold">Article 2 : Prestations en ligne</h2>
      <p className="ml-2">Daara Academy propose des prestations en ligne dans le domaine de l'éducation, de la formation et des services liés à l'informatique. Les prestations peuvent inclure des cours en ligne, des tutoriels, des services de conseil et toute autre activité en relation avec le domaine d'expertise de Daara Academy.</p>

      <h2 className="font-bold">Article 3 : Commandes et Tarifs</h2>
      <p className="ml-2">3.1 Commandes : Les clients peuvent passer leurs commandes de prestations en ligne directement sur le site internet de Daara Academy ou par tout autre moyen de communication prévu à cet effet. Les commandes sont soumises à l'acceptation de Daara Academy.</p>
      <p className="ml-2">3.2 Tarifs : Les tarifs des prestations en ligne sont indiqués en monnaie locale et peuvent être soumis à des taxes et frais supplémentaires selon la législation en vigueur. Les tarifs sont communiqués aux clients lors de la passation de la commande et peuvent être sujets à modification.</p>

      <h2 className="font-bold">Article 4 : Paiement</h2>
      <p className="ml-2">4.1 Modalités de paiement : Les clients peuvent régler leurs commandes en ligne par les moyens de paiement acceptés par Daara Academy. Les détails des modalités de paiement sont fournis aux clients lors de la passation de la commande.</p>
      <p className="ml-2">4.2 Facturation : Une facture détaillée est émise pour chaque commande. Les clients sont tenus de fournir des informations de facturation correctes et à jour.</p>

      <h2 className="font-bold">Article 5 : Propriété intellectuelle</h2>
      <p className="ml-2">5.1 Droits de propriété : Tous les droits de propriété intellectuelle relatifs aux prestations en ligne fournies par Daara Academy, y compris les contenus, les documents, les logiciels et les supports pédagogiques, sont la propriété exclusive de Daara Academy.</p>
      <p className="ml-2">5.2 Utilisation des contenus : Les clients s'engagent à utiliser les contenus fournis par Daara Academy uniquement à des fins personnelles et non commerciales. Toute reproduction, distribution ou utilisation des contenus à des fins commerciales est strictement interdite sans l'autorisation expresse de Daara Academy.</p>

      <h2 className="font-bold">Article 6 : Responsabilité</h2>
      <p className="ml-2">Daara Academy s'engage à fournir des prestations en ligne de qualité et à mettre en œuvre tous les moyens raisonnables pour assurer leur bon déroulement. Toutefois, Daara Academy décline toute responsabilité en cas de dommages directs ou indirects causés par l'utilisation des prestations en ligne, sauf en cas de faute intentionnelle ou de négligence grave.</p>

      <h2 className="font-bold">Article 7 : Confidentialité</h2>
      <p className="ml-2">Daara Academy s'engage à respecter la confidentialité des informations personnelles fournies par les clients. Les données personnelles collectées sont utilisées uniquement dans le cadre de la prestation des services et conformément à la législation en vigueur en matière de protection des données.</p>

      <h2 className="font-bold">Article 8 : Modification des CGV</h2>
      <p className="ml-2">Daara Academy se réserve le droit de modifier les présentes Conditions Générales de Vente à tout moment. Les clients seront informés des modifications apportées et auront la possibilité de les accepter ou de résilier leur contrat en cas de désaccord.</p>

      <h2 className="font-bold">Article 9 : Litiges et Droit applicable</h2>
      <p className="ml-2" >Tout litige relatif aux présentes Conditions Générales de Vente sera soumis à la compétence exclusive des tribunaux compétents du Sénégal. Les présentes CGV sont régies par le droit sénégalais.</p>

      <p className="mt-4">Fait à Dakar, le 01 avril 2024</p>

      <p>Daara Academy</p>
      <p>SN DKR 2023 A 14219</p>
      <p>Téléphone : +221 77 345 00 01</p>
      <p>Email : contact@daaraacademy</p>
    </article>
  </div>
  );
}

export default CGV