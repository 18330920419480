import React from 'react';

const Mentions = () => {
  return (
    <div className="container mx-auto p-6">
    <h1 className="text-3xl font-bold mb-6">Mentions Légales</h1>
    <article className="prose">
      <h2 className="font-bold">1. Informations légales</h2>
      <p className="ml-2">Raison sociale : Daara Academy</p>
      <p className="ml-2">Numéro d'immatriculation : SN DKR 2023 A 14219</p>
      <p className="ml-2">Téléphone : +221 77 345 00 01</p>
      <p className="ml-2">Email : contact@daaraacademy</p>

      <h2 className="font-bold">2. Directeur de la publication</h2>
      <p className="ml-2">Le directeur de la publication est Lo Awa, en qualité de Poste du directeur.</p>

      <h2 className="font-bold">3. Hébergeur du site</h2>
      <p className="ml-2">Le site est hébergé par PlanetHoster</p>
      <p className="ml-2">Adresse : Canada</p>
      <p className="ml-2">Téléphone : CA:+1 844 629-6753</p>
     
      <h2 className="font-bold">4. Propriété intellectuelle</h2>
      <p className="ml-2">Tous les éléments constituant le site de Daara Academy, tels que les textes, les graphiques, les logos, les images, les vidéos, les icônes, ainsi que leur mise en forme, sont la propriété exclusive de Daara Academy ou de leurs propriétaires respectifs.</p>
      <p className="ml-2">Toute reproduction, distribution, modification, ou utilisation sans autorisation préalable est strictement interdite.</p>

      <h2 className="font-bold">5. Limitation de responsabilité</h2>
      <p className="ml-2">Daara Academy ne pourra être tenu responsable de tout dommage direct ou indirect résultant de l'utilisation du site ou de l'impossibilité d'y accéder, ainsi que des dommages résultant de l'utilisation des informations fournies sur le site.</p>

      <h2 className="font-bold">6. Liens externes</h2>
      <p className="ml-2">Le site de Daara Academy peut contenir des liens vers des sites tiers. Daara Academy n'exerce aucun contrôle sur le contenu ou les pratiques de ces sites et décline toute responsabilité quant à leur contenu ou leur utilisation.</p>

      <h2 className="font-bold">7. Droit applicable</h2>
      <p className="ml-2">Les présentes mentions légales sont régies par le droit sénégalais.</p>
    </article>
  </div>
  );
}

export default Mentions