  export const customStyles = {
  
    option: (base: any, { isFocused }: { isFocused: boolean }) => ({
        ...base,
        color: "black",
        background: "white",
        ...(isFocused && {
          color: "white",
          background: "#174538",
        }),
      }),
  };