import React from "react";

type ButtonProps = {
  callback: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  label: string;
  extraStyle?: string;
  icon?: JSX.Element;
  colorText?:string;
};

const Button: React.FC<ButtonProps> = ({
  callback,
  disabled = false,
  isLoading = false,
  label,
  extraStyle = "",
  icon,
  colorText="text-white"
}) => {
  const handleClick = () => {
    if (!disabled && !isLoading) {
      callback();
    }
  };

  return (

    <button

    disabled={disabled || isLoading}
    onClick={handleClick}
    type="submit"
    className={`group relative flex w-full justify-center rounded-md bg-secondary py-2 px-3 text-sm font-semibold  
    hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 
    focus-visible:outline-green-900 ${colorText} ${extraStyle}`}
  >
    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                 {icon}
                </span>
                {isLoading ? "Loading..." : label}
  </button>
   
  );
};

export default Button;