import { createSlice } from "@reduxjs/toolkit";


interface globalState {
 
    status: string;
    showSidebar: boolean;
    lessonId: string;
    courseId: string;
    
}

// Define the initial state using that type
const initialState: globalState = {
    status: "",
    showSidebar: true,
    lessonId: "",
    courseId: "",
    
};

export const globalSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        setStatus: (state, action) => {
            state.status = action.payload.data;
        },
        setShowSidebar: (state, action) => {
            state.showSidebar = action.payload;
        },
        setLessonId: (state, action) => {
            state.lessonId = action.payload;
        },
        setCourseId: (state, action) => {
            state.courseId = action.payload;
        },
    },
});

export const { setStatus, setShowSidebar, setLessonId, setCourseId } = globalSlice.actions;

export default globalSlice.reducer;
