import React from 'react';
import QCMView from '../components/qcm/qcmView';

const QCM: React.FC = () => {

    return(
        <QCMView/>
    )
};

export default QCM;
