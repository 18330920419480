import { store } from "../store";

export function isEmptyString(str: string): boolean {
    return str.trim().length === 0;
  }

export function formatDate(dateStr: string) {
  const options = { day: "numeric", month: "long", year: "numeric" } as Intl.DateTimeFormatOptions;
  const date = new Date(dateStr);
  return date.toLocaleDateString("fr-FR", options);
  }
  

 export const isApproved = (projectId:string | undefined)=>{
  if (!projectId) {
    return false
  }
    return store.getState().entities.approvedProjects.some((project) => project.project === projectId && project.isApproved);
  }
  export const isEnable = (c:string | undefined)=>{

    if (!c) {
      return false
    }

    const course = store.getState().entities.courses.find((course) => course.id === c);
  
    let prevcourse:any=null;
    if(course){
      if(course.order == 1 || course.order == 2)
      return true
      prevcourse = store.getState().entities.courses.find((c) => c?.order === course?.order-1);
      return isApproved(prevcourse?.project)
    }
    return false
    
  }

  export const findCourseIdByProjectId = (projectId:string | undefined)=>{
    if (!projectId) {
      return undefined
    }

    const course = store.getState().entities.courses.find((course) => course.project === projectId);

    return course?.id


  }