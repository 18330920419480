import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { FiChevronRight} from 'react-icons/fi';
import { setCourseId, setLessonId, setShowSidebar } from '../../redux/global';
import { RootState } from '../../store';
import { Course } from '../../react-app-env';

const ProjectView: React.FC = () => {

  //Use dispatch
const dispatch = useDispatch();

    //Use navigate
    const history = useNavigate();

//use dispatchselector
const courses = useSelector((state: RootState) => state.entities.courses);
const lessons = useSelector((state: RootState) => state.entities.lessons);



    useEffect(() => {
        dispatch(setShowSidebar(true));    
        
    }, []);
    const filteredCourses = courses.filter((course) => course.have_project && course.enabled);
    const redirectToProject = (course:Course) => {
        if(course?.have_project && !course.url){
            const filteredLessons = lessons.filter((lesson) => lesson.course === course.id);
        dispatch(setLessonId(filteredLessons[0].id));
        dispatch(setCourseId(course.id));
          history(`/editor/${course?.project}`);
        }
        else {
          if(course?.url)
          window.open(course.url, '_blank');
        }
    }
        

    return(<div className="">
       
        <div className="mx-auto container py-8">
                <div className="flex flex-wrap">
                  {filteredCourses.map((course, index) => {

                      return <>
                       <div className="focus:outline-none mx-2 md:w-72 w-64 mb-8 md:mb-4" key={index}>
                        <div className="bg-white">
        
                            <div className="p-4">
                                <div className="flex items-center justify-center">
                                    <h2 className="mb-2 text-xs md:text-base font-bold tracking-tight">{course?.name}</h2>
                                </div>
                    
                                <div className="flex mt-4 items-center justify-center">
                                <button
                                    onClick={() => redirectToProject(course)}
                                    className="inline-flex items-center px-3 py-2 text-center text-white bg-primary rounded-lg hover:bg-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 "
                                    >
                                    <div className="flex items-center font-bold  text-xs md:text-base ">
                                        <span className="font-source-sans-pro cursor-pointer mr-2">Projet</span>
                                        <FiChevronRight size={35} />
                                    </div>
                                    </button>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                      </>
                  })}
                </div>
        </div>
    </div>);
};

export default ProjectView;
