// React libraries
import { createSlice } from "@reduxjs/toolkit";

type Authentication = {
    isAuthenticated: boolean;
    token: string | null;
};

// Define the initial state using that type
const initialState: Authentication = {
    isAuthenticated: sessionStorage.getItem("isAuthenticated") === "true",
    token: sessionStorage.getItem("token"),
};

export const authenticationSlice = createSlice({
    name: "authentication",
    initialState,
    reducers: {
        setAuthentication: (state, action) => action.payload,
    },
});

export const { setAuthentication } = authenticationSlice.actions;

export default authenticationSlice.reducer;
