//Axios
import { getJSON } from "../axios/axios";

//Constants
import {api} from "../constants/api";
import { HTTP_SUCCESS, HTTP_UNAUTHORIZED } from "../constants/httpStatus";
import { setAuthentication } from "../redux/authentication";

//Redux
import { setEntity } from "../redux/entities";
import { setProfile } from "../redux/profile";

//Store
import type { AppDispatch } from "../store";
import { store } from "../store";


export const fetchEntities = () => {
    // Start performance measure
    const t0 = performance.now();
  
    const promises = [
        store.dispatch(apiFetchEntities("courses")),
        store.dispatch(apiFetchEntities("lessons")),
        store.dispatch(apiFetchEntities("replays")),
        store.dispatch(apiFetchEntities("approvedProjects")),
    ];

    // Check if the user is a superuser before dispatching "users" API fetch
    if (store.getState().profile.is_superuser) {
        promises.push(store.dispatch(apiFetchEntities("users")));
    }

    Promise.all(promises).then(() => {
        const t1 = performance.now();
        // console.log("Promises loaded in " + (t1 - t0) / 1000 + " seconds.");
    });
};


const apiFetchEntities = (entityName: string) => (dispatch: AppDispatch) => {
    getJSON(`/${entityName}/`).then((response) => {
        if (response.status === HTTP_SUCCESS) {
            const results=entityName==='approvedProjects'?response.data:response.data.results
            dispatch(setEntity({ attribute: entityName, data: results }));
        }
        else{
            if(response.status === HTTP_UNAUTHORIZED){
                //update localstorage
                sessionStorage.setItem("isAuthenticated", "false");
                sessionStorage.setItem("token", "");
                sessionStorage.setItem("profile", "");
         
                //Update Authentication in Redux
                dispatch(setAuthentication({ isAuthenticated: false, token: "" }));
                dispatch(setProfile(null));
            }
            
        }
    });
};



