//React librairies
import type React from "react";
import { useState } from "react";
import Icon from "@mdi/react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

//Constants
import { api } from "../../constants/api";

//Icons
import { FiMail as Mail } from "react-icons/fi";

//Selectors
import TextInput from "../../components/UI/TextInput";
import Button from "../../components/UI/Button";

//Services
import { postJSON } from "../../axios/axios";

//Utils
import { isEmptyString } from "../../utils/commons.utils";

//pages
import Information from "./information";
import { ROUTES } from "../../routes/routerConfig";

const ResetPassword: React.FC = () => {
    //Use navigate
    const history = useNavigate();

    //Use state
    const [isLoading, setIsLoading] = useState(false);
    const [reset, setReset] = useState<any>({
        email: "",
        isSent: false,
        password: "",
        password_confirm: "",
    });


    const onChangeReset = (name: string, value: string | boolean) => {
        setReset({
            ...reset,
            [name]: value,
        });
    };

    const checkEmail = () => {
        setIsLoading(true);
        postJSON(api.check_email, {
            email: reset.email,
        }).then((response) => {
            setIsLoading(false);
            if (response) {
                if (response?.status === 200) {
                    onChangeReset("isSent", true);
                } else {
                    toast.error(response.data);
                }
            } else {
                return window.alert("A connectivity problem has been detected !");
            }
        });
    };

    const redirectToLogin = () => {
        history(ROUTES.LOGIN);
    };

    const canCheckEmail = isEmptyString(reset.email);

    return (
        <div
          className="relative min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-no-repeat bg-cover relative items-center"
          
      >
          <div className="max-w-md w-full rounded-xl z-10">

              <div className="space-y-8 px-10 pb-10 border-2 p-30 mt-75 bg-white outline-none border-[#CDCDCD] rounded">
                  <div className="text-center mt-10">
                      <div className="text-center">
                          <img className="w-60 mx-auto" src="/logo-green.png" alt="Daara Academy © " />
                      </div>
                      <h2 className="mt-6 text-2xl font-bold text-primary">REINITIALISATION DU MOT DE PASSE</h2>
                  </div>
                  <hr className="text-primary"/>
                  {!reset.isSent && (
                                <>
                  <div className="mt-8 space-y-6">
                      <div className="content-center">
                          <TextInput
                              icon={<Mail className="text-[#808080] text-lg" />}
                              label={"Email"}
                              name="email"
                              onChange={onChangeReset}
                              placeholder={"Email"}
                              value={reset.email}
                              type={"text"}
                          />
                      </div>
                  
                      <div className={"mt-3"}>
                      <Button
                                isLoading={isLoading}
                                callback={checkEmail} disabled={canCheckEmail}
                                label={"Réinitialiser"}
                                colorText={"text-black"}
                                extraStyle={"text-lg"}
                            />
                                <div className="flex items-center justify-center mt-4">
                          <div className="text-sm">
                              <p
                                  className="font-medium text-[#808080] hover:text-blue hover:underline cursor-pointer"
                                  onClick={redirectToLogin}
                              >
                                  Retour à la connexion
                              </p>
                          </div>
                      </div>
                      </div>
                  </div>
                  </>
                   )}
                   {reset.isSent && (
                       <>
                           <div className=" text-center mt-8 space-y-6">
                        <div className="text-center">
                               <h1 className="font-bold text-2xl text-black mb-2">Envoyé</h1>
                               <p className="text-lg">Un email de réinitialisation a été envoyé à votre adresse. Veuillez vérifier votre boîte de réception.</p>
                           </div>
                           </div>
                       </>
                   )}
                 </div>
                <Information/>

          </div>

      </div>
    )

};

export default ResetPassword;
