//React librairies
import type React from "react";
import { useState } from "react";
import Icon from "@mdi/react";
import { useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";

//Constants
import { api } from "../../constants/api";

//Icons MDI
import { mdiLockOutline as Password } from "@mdi/js";

//Selectors
import TextInput from "../../components/UI/TextInput";
import Button from "../../components/UI/Button";

//Services
import { postJSON } from "../../axios/axios";

//Utils
import { isEmptyString } from "../../utils/commons.utils";

//pages
import Information from "./information";
import { ROUTES } from "../../routes/routerConfig";

const ResetPasswordConfirm: React.FC = () => {
    //Use navigate
    const history = useNavigate();

    //Use params
    const params = useParams();

    //Use state
    const [isLoading, setIsLoading] = useState(false);
    const [reset, setReset] = useState<any>({
        password: "",
        password_confirm: "",
        isDone: false,
        uid: params.uid,
        token: params.token,
    });

    const onChangeReset = (name: string, value: string | boolean) => {
        setReset({
            ...reset,
            [name]: value,
        });
    };

    const resetPassword = () => {

        setIsLoading(true);
        postJSON(api.reset_password, reset).then((response) => {
            setIsLoading(false);
            if (response) {
                if (response?.status === 200) {
                    toast.success('Modification du mot de passe réussie');
                    onChangeReset("isDone", true);
                } else {
                    toast.error(response.data);
                }
            } else {
                return window.alert("A connectivity problem has been detected !");
            }
        });
    };

    const redirectToLogin = () => {
        history(ROUTES.LOGIN);
    };

    const canResetPassword = ["password_confirm", "password"].some((attribute: string) => isEmptyString(reset[attribute])) || isLoading;

return (
    <div
    className="relative min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-no-repeat bg-cover relative items-center"
    
>
    <div className="max-w-md w-full rounded-xl z-10">

        <div className="space-y-8 px-10 pb-10 border-2 p-30 mt-75 bg-white outline-none border-[#CDCDCD] rounded">
            <div className="text-center mt-10">
                <div className="text-center">
                    <img className="w-60 mx-auto" src="/logo-green.png" alt="Daara Academy © " />
                </div>
                <h2 className="mt-6 text-2xl font-bold text-primary">MODIFICATION DU MOT DE PASSE</h2>
            </div>
            <hr className="text-primary"/>
            {!reset.isDone && (
                          <>
            <div className="mt-8 space-y-6">
                <div className="content-center">
                    
                <TextInput
                              icon={<Icon path={Password} size={1} className="text-[#808080] text-lg" />}
                              label={"Mot de passe"}
                              name="password"
                              onChange={onChangeReset}
                              placeholder={"Mot de passe"}
                              type="password"
                              value={reset.password}
                          />
                </div>
                <div className="content-center">
                <TextInput
                    icon={<Icon path={Password} size={1} className="text-[#808080] text-lg" />}
                    label={"Confirmation du mot de passe"}
                    name="password_confirm"
                    onChange={onChangeReset}
                    placeholder={"Confirmation du mot de passe"}
                    type="password"
                    value={reset.password_confirm}
                />
                </div>
            
                <div className={"mt-3"}>
                <Button
                          isLoading={isLoading}
                          callback={resetPassword} disabled={canResetPassword}
                          label={"Enregistrer"}
                          colorText={"text-black"}
                          extraStyle={"text-lg"}
                      />
                          <div className="flex items-center justify-center mt-4">
                    <div className="text-sm">
                        <p
                            className="font-medium text-[#808080] hover:text-blue hover:underline cursor-pointer"
                            onClick={redirectToLogin}
                        >
                            Retour à la connexion
                        </p>
                    </div>
                </div>
                </div>
            </div>
            </>
             )}
             {reset.isDone && (
                 <>
                     <div className=" text-center mt-8 space-y-6">
                  <div className="text-center">
                         <h1 className="font-bold text-2xl text-black mb-2">Mot de Passe initialisé</h1>
                         <p className="text-lg">Votre mot de passe a été réinitialisé avec succès. Veuillez vous connecter maintenant.</p>
                     </div>
                     <Button callback={redirectToLogin} label={"Connexion"} />
                     </div>
                 </>
             )}
           </div>
           <Information/>

    </div>

</div>
)

};

export default ResetPasswordConfirm;
