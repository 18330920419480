//React libraries
import { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

//Typescript types
import type { RootState } from "../store";
import { ROUTES } from "./routerConfig";

function PrivateRoute() {
    //Use state
    const [isAuthenticated] = useState(useSelector((state: RootState) => state.authentication.isAuthenticated));

    return isAuthenticated ? <Outlet /> : <Navigate to={ROUTES.LOGIN} />;
}

export default PrivateRoute;
