//React libraries
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

//Components
import SideBar from "./components/sideBar/sideBar";
import Header from "./components/header/header";
import Footer from "./components/footer/footex";
import Spinner from "./components/UI/Spinner";

//Routing
import Routing from "./routes/routing";

//Services
import { fetchEntities } from "./services/entities";

//Typescript types
import type { RootState } from "./store";



function App(): JSX.Element | null {
     
    //Use selector
    const { showSidebar } = useSelector((state: RootState) => state.global);


    const { isAuthenticated } = useSelector((state: RootState) => state.authentication);

    useEffect(() => {
        if (isAuthenticated) {
        fetchEntities();
        }
    }, [isAuthenticated]);



    return (
      <BrowserRouter basename="/">
      <div>
      <div className="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-[#F8F7F5] text-black">
      
      {!isAuthenticated && (
        <>
          <div className="h-full transition-all duration-300">
            <Routing />
          </div>
        </>
      )}

{isAuthenticated && (
        <>
          <Header />
          {showSidebar && (
          <SideBar/>
          )}
          <div className={`h-full mb-10 mt-20 mr-2 md:mr-10 ${
                                  showSidebar ? "ml-20 md:ml-80 " : "ml-2"
                              }`}>

         <Routing />
         
         <Footer/>
          </div>
         
        </>
      )}
        </div>
                  <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          />
          <ToastContainer />
      </div>
</BrowserRouter>
    )

}

export default App;

