
interface TextInputProps {
  icon: JSX.Element;
  label: string;
  name: string;
  onChange: (name: string, value: string) => void;
  placeholder: string;
  value: string;
  type: string;
}

 const TextInput: React.FC<TextInputProps> = ({
  icon,
  label,
  name,
  onChange,
  placeholder,
  value,
  type,
}) => {

  return ( <div className="flex items-center text-lg mb-6 md:mb-8">
  <div className="absolute ml-3">
   {icon}
  </div>
  <input id={name}
           name={name}
           onChange={(e)=>onChange(name,e.target.value)}
           placeholder={placeholder}
           type={type}
           value={value}
           autoComplete={name}
           required className="bg-gray-200 pl-12 py-2 md:py-4 focus:outline-none w-full
           block h-10 px-4 text-lg text-gray-700 placeholder-gray-400 bg-white border border-gray-200 
           rounded-md shadow-sm focus:outline-none focus:ring-green-900 focus:border-green-900 transition duration-300" />
</div>)

};
export default TextInput;