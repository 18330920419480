// React libraries
import { configureStore } from '@reduxjs/toolkit';

// Features
//import applicationReducer from './redux/application/application';
import authenticationReducer from './redux/authentication';
import profileReducer from './redux/profile';
import entitiesReducer from './redux/entities';
import globalReducer from './redux/global';


export const store = configureStore({
    reducer: {
        authentication: authenticationReducer,
        entities: entitiesReducer,
        profile: profileReducer,
        global: globalReducer
        //theme: themeReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
