//React libraries
import axios from "axios";

//Redux store
import { store } from "../store";

/**
 * Get user token
 */
export const getToken = () => {
    const token: string | null = store.getState()?.authentication?.token;

    if (token) {
        return token;
    } else {
        return null;
    }
};

// Allow user to get cookie
// Helpfull for the CSRF TOKEN who allow us to upload url link
// More details here:  https://docs.djangoproject.com/en/4.1/ref/csrf/#ajax
function getCookie(name: string) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === name + "=") {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

/**
 * Generate HTTP request
 */
const makeRequest = () => {

    //Set axios instance
    const instance = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_SERVER,
    });

    //Set content
    instance.defaults.headers.post["Content-Type"] = "application/json";

    const token = getToken();
    if (token) {
        instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    }

    const csrfToken = getCookie("csrftoken");
    if (csrfToken) {
        instance.defaults.headers.common["X-CSRFToken"] = csrfToken;
    }
    // Intercept request to show the spinner
   /* instance.interceptors.request.use((config) => {
        store.dispatch(setShowSpinner(true));
        return config;
      });
    
      // Intercept response to hide the spinner
      instance.interceptors.response.use(
        (response) => {
           store.dispatch(setShowSpinner(false));
          return response;
        },
        (error) => {
           store.dispatch(setShowSpinner(false));
          return Promise.reject(error);
        }
      );*/
    return instance;
};

export const getJSON = (url: string) =>
    makeRequest()
        .get(url)
        .then((response) => response)
        .catch((error) => error?.response);

          

export const postJSON = (url: string, body: object) =>
    makeRequest()
        .post(url, body)
        .then((response) => response)
        .catch((response) => response?.response);

export const postBlob = (url: string, body: object) =>
    makeRequest()
        .post(url, body, {
            responseType: "blob",
        })
        .then((response) => response)
        .catch((error) => error?.response);

export const patchJSON = (url: string, body: object) =>
    makeRequest()
        .patch(url, body)
        .then((response) => response)
        .catch((response) => response?.response);

export const deleteJSON = (url: string) =>
    makeRequest()
        .delete(url)
        .then((response) => response)
        .catch((response) => response?.response);