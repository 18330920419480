import React from "react";
import { BiLogOut as LogOut } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

//redux
import { setAuthentication } from "../../redux/authentication";
import { setProfile } from "../../redux/profile";
import { ROUTES } from "../../routes/routerConfig";


function Footer(): JSX.Element {

    //Use dispatch
   const dispatch = useDispatch();
      //Use navigate
   const history = useNavigate();

   const logOut = () => {
    //SET SESSION
    sessionStorage.setItem("isAuthenticated", "false");
    sessionStorage.setItem("token", "");
    sessionStorage.setItem("profile", "");

    //Update Authentication in Redux
    dispatch(setAuthentication({ isAuthenticated: false, token: "" }));
    dispatch(setProfile(null));

    //REDIRECT to login
    history(ROUTES.LOGIN);
};

return (
    
<footer className="rounded-lg shadow mt-10">
  <div className="w-full max-w-screen-xl p-4 md:py-8">
    <div className="sm:flex sm:items-center sm:justify-center m-4">
      <button
        className="flex items-center mr-4 hover:underline hover:bg-theme1 dark:hover:bg-green-900 p-1 md:p-2.5 rounded-lg text-xs md:text-sm"
        onClick={logOut}
      >
        <span className="inline-flex mr-1">
          <LogOut className="w-5 h-5" />
        </span>
        <span>Me déconnecter</span>
      </button>
    </div>
    <div className="sm:flex sm:items-center sm:justify-center">
      <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
        <li>
          <a href="/cgv" className="mr-4 hover:underline md:mr-6">
            CGV
          </a>
        </li>
        <li>
          <a href="/confidentiality" className="mr-4 hover:underline md:mr-6">
            CONFIDENTIALITÉ
          </a>
        </li>
        <li>
          <a href="/mentions" className="mr-4 hover:underline md:mr-6">
            MENTIONS
          </a>
        </li>
      </ul>
    </div>
    <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
    <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">
      © 2023 <a href="/" className="hover:underline">
        Daara Academy™
      </a>
      . All Rights Reserved.
    </span>
  </div>
</footer>



);
}
export default Footer;