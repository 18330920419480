//React librairies
import type React from "react";
import { useState } from "react";
import Icon from "@mdi/react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

//Constants
import { api } from "../../constants/api";

//Icons
import { FiMail as Mail } from "react-icons/fi";
import { BiText as Text } from "react-icons/bi"

//Selectors
import TextInput from "../../components/UI/TextInput";
import Button from "../../components/UI/Button";

//Services
import { postJSON } from "../../axios/axios";

//Utils
import { isEmptyString } from "../../utils/commons.utils";

//pages
import Information from "./information";
import { ROUTES } from "../../routes/routerConfig";

const Signup: React.FC = () => {
    //Use navigate
    const history = useNavigate();

    //Use state
    const [isLoading, setIsLoading] = useState(false);
    const [signup, setSignup] = useState<any>({
        email: "",
        first_name: "",
        last_name: "",
        isSent: false,
    });


    const onChangeSignup = (name: string, value: string | boolean) => {
        setSignup({
            ...signup,
            [name]: value,
        });
    };

    const save = () => {
        setIsLoading(true);
        postJSON(api.signup, {
            email: signup.email,
            first_name: signup.first_name,
            last_name: signup.last_name,
        }).then((response) => {
            setIsLoading(false);
            if (response) {
                if (response?.status === 200) {
                    onChangeSignup("isSent", true);
                    toast.success(response.data);
                } else {
                    toast.error(response.data);
                }
            } else {
                return window.alert("A connectivity problem has been detected !");
            }
        });
    };

    const redirectToLogin = () => {
        history(ROUTES.LOGIN);
    };

    const cansave = ["email", "first_name", "last_name"].some((attribute: string) => isEmptyString(signup[attribute])) || isLoading;

    return (
        <div
          className="relative min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-no-repeat bg-cover relative items-center"
          
      >
          <div className="max-w-md w-full rounded-xl z-10">

              <div className="space-y-8 px-10 pb-10 border-2 p-30 mt-75 bg-white outline-none border-[#CDCDCD] rounded">
                  <div className="text-center mt-10">
                      <div className="text-center">
                          <img className="w-60 mx-auto" src="/logo-green.png" alt="Daara Academy © " />
                      </div>
                      <h2 className="mt-6 text-2xl font-bold text-primary">INSCRIPTION</h2>
                  </div>
                  <hr className="text-primary"/>
                  {!signup.isSent && (
                                <>
                  <div className="mt-8 space-y-6">
                      <div className="content-center">
                          <TextInput
                              icon={<Text className="text-[#808080] text-lg" />}
                              label={"Prénom"}
                              name="first_name"
                              onChange={onChangeSignup}
                              placeholder={"Prénom"}
                              value={signup.first_name}
                              type={"text"}
                          />
                      </div>
                      <div className="content-center">
                      <TextInput
                              icon={<Text className="text-[#808080] text-lg" />}
                              label={"Nom"}
                              name="last_name"
                              onChange={onChangeSignup}
                              placeholder={"Nom"}
                              value={signup.last_name}
                              type={"text"}
                          />
                      </div>
                      <div className="content-center">
                          <TextInput
                              icon={<Mail className="text-[#808080] text-lg" />}
                              label={"Email"}
                              name="email"
                              onChange={onChangeSignup}
                              placeholder={"Email"}
                              value={signup.email}
                              type={"text"}
                          />
                      </div>
                  
                      <div className={"mt-3"}>
                      <Button
                                isLoading={isLoading}
                                callback={save} disabled={cansave}
                                label={"S'inscrire"}
                                colorText={"text-black"}
                                extraStyle={"text-lg"}
                            />
                                <div className="flex items-center justify-center mt-4">
                                    <div className="text-sm">
                                        <p
                                            className="font-medium text-[#808080] hover:text-blue hover:underline cursor-pointer"
                                            onClick={redirectToLogin}
                                        >
                                            déjà un compte : Se connecter
                                        </p>
                                    </div>
                                </div>
                      </div>
                  </div>
                  </>
                   )}
                   {signup.isSent && (
                       <>
                           <div className=" text-center mt-8 space-y-6">
                        <div className="text-center">
                               <h1 className="font-bold text-2xl text-black mb-2">Inscription réussie</h1>
                               <p className="text-lg">Un email contenant vos informations de connexion a été envoyé à votre adresse. Veuillez vérifier votre boîte de réception.</p>
                           </div>
                           </div>
                       </>
                   )}
                 </div>
                <Information/>

          </div>

      </div>
    )

};

export default Signup
