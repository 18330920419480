import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Lesson } from '../../react-app-env';
import { FiChevronRight } from 'react-icons/fi';
import { setCourseId, setLessonId, setShowSidebar } from '../../redux/global';
import { RootState } from '../../store';
import CourseSelector from '../UI/CourseSelector';

const QCMView: React.FC = () => {
  const dispatch = useDispatch();
  const lessons = useSelector((state: RootState) => state.entities.lessons);

  const [selectedLessons, setSelectedLessons] = useState<Lesson[]>([]);
  const [course, setCourse] = useState<any>(null);

  useEffect(() => {
    dispatch(setShowSidebar(true));
  }, []);

  const onChange = (module: any) => {
    setCourse(module);
    const filteredLessons = lessons.filter((lesson) => lesson.course === module.value && lesson.enabled);
    if (filteredLessons.length > 0) {
      dispatch(setLessonId(filteredLessons[0]?.id));
      dispatch(setCourseId(module.value));
    }

    setSelectedLessons(filteredLessons);
  };

  return (
    <div className="">
      <CourseSelector onChange={onChange} value={course} />

      <div className="mx-auto container py-8">
        <div className="flex flex-wrap">
          {selectedLessons.length === 0 ? (
               course ? (
                <p className="text-center text-red-500 font-bold">Module désactivé.</p>
              ) : (<p className="text-center text-red-500 font-bold">Sélectionner un module.</p>)
           
          ) : (
            selectedLessons.map((lesson, index) => (
              <div className="focus:outline-none mx-2 md:w-72 w-64 mb-8 md:mb-4" key={index}>
                <div className="bg-white">
                  <div className="p-4">
                    <div className="flex items-center justify-center">
                      <NavLink to={`/lessons/${lesson?.id}`}>
                        <h2 className="mb-2 text-xs md:text-base font-bold tracking-tight">{lesson?.title}</h2>
                      </NavLink>
                    </div>

                    <div className="flex mt-4 items-center justify-center">
                      <NavLink
                        to={`/lessons/${lesson?.id}/questions`}
                        className="inline-flex items-center px-3 py-2 text-xs md:text-base font-medium text-center text-white bg-primary rounded-lg hover:bg-secondary focus:ring-4 focus:outline-none focus:ring-blue-300"
                      >
                        QCM
                        <FiChevronRight size={35} />
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default QCMView;
