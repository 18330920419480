
import React from 'react';

const Confidentiality = () => {
  return (
   
  <div className="container mx-auto p-6">
    <h1 className="text-3xl font-bold mb-6">Politique de Confidentialité</h1>
    <p>Daara Academy s'engage à respecter la confidentialité des informations personnelles fournies par ses clients. La présente politique de confidentialité décrit les pratiques de Daara Academy en matière de collecte, d'utilisation et de partage des informations personnelles.</p>
    <article className="prose">
    <h2 className="font-bold">1. Collecte des informations personnelles</h2>
<p className="ml-2">Daara Academy peut collecter des informations personnelles auprès de ses clients, notamment :</p>
<ul className="ml-2">
  <li>Nom et prénom</li>
  <li>Adresse email</li>
  <li>Numéro de téléphone</li>
  <li>Adresse postale</li>
  <li>Informations de paiement</li>
  <li>Informations relatives à l'utilisation des services de Daara Academy</li>
</ul>

<h2 className="font-bold">2. Utilisation des informations personnelles</h2>
<p className="ml-2">Daara Academy utilise les informations personnelles collectées auprès de ses clients pour :</p>
<ul className="ml-2">
  <li>Fournir les services demandés</li>
  <li>Envoyer des informations et des communications relatives aux services</li>
  <li>Améliorer la qualité des services</li>
  <li>Réaliser des analyses et des statistiques</li>
  <li>Respecter les obligations légales</li>
</ul>

<h2 className="font-bold">3. Partage des informations personnelles</h2>
<p className="ml-2">Daara Academy ne partage pas les informations personnelles de ses clients avec des tiers, sauf dans les cas suivants :</p>
<ul className="ml-2">
  <li>Avec le consentement du client</li>
  <li>Lorsque la loi l'exige</li>
  <li>Aux fins de la fourniture des services</li>
</ul>

<h2 className="font-bold">4. Sécurité des informations personnelles</h2>
<p className="ml-2">Daara Academy s'engage à mettre en œuvre les mesures techniques et organisationnelles nécessaires pour protéger les informations personnelles de ses clients contre la perte, le vol, l'utilisation abusive, l'accès non autorisé, la modification ou la destruction.</p>

<h2 className="font-bold">5. Droits des clients</h2>
<p className="ml-2">Les clients ont le droit de :</p>
<ul className="ml-2">
  <li>Accéder à leurs informations personnelles</li>
  <li>Demander la rectification de leurs informations personnelles</li>
  <li>Demander la suppression de leurs informations personnelles</li>
  <li>S'opposer au traitement de leurs informations personnelles</li>
  <li>Retirer leur consentement au traitement de leurs informations personnelles</li>
</ul>

<h2 className="font-bold">6. Contact</h2>
<p className="ml-2">Pour toute question relative à la présente politique de confidentialité, les clients peuvent contacter Daara Academy par email à l'adresse <a href="mailto:contact@daaraacademy.com">contact@daaraacademy.com</a>.</p>

<h2 className="font-bold">7. Modification de la politique de confidentialité</h2>
<p className="ml-2">Daara Academy se réserve le droit de modifier la présente politique de confidentialité à tout moment. Les clients seront informés des modifications apportées et auront la possibilité de les accepter ou de résilier leur contrat en cas de désaccord.</p>

<p className="mt-4">Fait à Dakar, le 01 avril 2024</p>
<p>Daara Academy</p>
<p>SN DKR 2023 A 14219</p>
<p>Téléphone : +221 77 345 00 01</p>
<p>Email : <a href="mailto:contact@daaraacademy.com">contact@daaraacademy.com</a></p>
    </article>
  </div>
  );
}

export default Confidentiality