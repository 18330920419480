export const ROUTES = {
    HOME: '/',
    DOCUMENT_UPDATE: '/documents/:id/edit',
    DOCUMENT_CREATE: '/documents/create',
    DOCUMENTS: '/documents',
    LOGIN:'/login',
    SIGNUP: '/signup',
    RESET_PASSWORD_CONFIRM:'/reset-password/:uid/:token',
    RESET_PASSWORD:'/reset-password',
    EDITOR:'/editor/:id',
    SHARE:'/share/:id/:project',
    LESSON: '/lessons/:id',
    QUESTIONS: '/lessons/:id/questions',
    CGV:'/cgv',
    PROJECTS:'/projects',
    QCM:'/qcm',
    CONFIDENTIALITE: '/confidentiality',
    MENTIONS: '/mentions',
    DASHBOARD: '/dashboard',
    REPLAYS: '/replays',
    REPLAY: '/replays/:id',
    VALIDATE_PROJECT: '/validate-project'
  };
