import React from "react";
import {InformationCircleIcon } from '@heroicons/react/20/solid'


function Information(): JSX.Element {

return (
    <div className="flex items-center justify-center mt-8">
    <div className="text-sm flex items-center">
      <span className="font-medium text-black">
      <InformationCircleIcon className="h-5 w-5 mr-1 inline-block" />
      <b>Si vous ne parvenez pas à vous connecter</b> à votre espace membre, veuillez nous contacter par email à{" "}
        <a href="contact@daaraacademy.com" className="hover:text-blue hover:underline cursor-pointer">
          <b>contact@daaraacademy.com </b>
        </a>
        afin que l'on puisse vous aider le plus rapidement possible.
      </span>
    </div>
</div>
);
}
export default Information;