import { useSelector } from "react-redux";
import Select from 'react-select';
import { customStyles } from "../../constants/style";
import { RootState } from "../../store";


type Props = {
    onChange:(value:string)=>void; 
    value: any;
    };


function CourseSelector({onChange,value}:Props): JSX.Element {

    
    const courses = useSelector((state: RootState) => state.entities.courses);
    const filteredCourses = courses.filter((course) => course.name !=="Introduction");
    const options: any[] = filteredCourses.map((course) => ({
        value: course.id,
        label: course.name,
      }));

  const handleChange = (selected:any) => {
    onChange(selected)
  };
 
  return (  
      <div className="md:w-full w-64">
    <Select options={options} onChange={handleChange} styles={customStyles} value={value} placeholder={"Selectionner un module ..."}/>
    </div>
  );
};

export default CourseSelector;
