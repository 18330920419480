export const api = {
    login :"api-token-auth",
    check_email:"check-mail-auth",
    reset_password:"reset-password-auth",
    signup:"api-signup-auth",
    lessons :{
        one : "lessons/",
        questions:"/questions/",
        answerstudents: "/answerstudent/",
        qcm_progress: "/qcm-progress/"
    },
    projects :{
        one : "projects/",
    },
    projectsWeb : {
        one : "projectsWeb/",
        create : "projectsWeb/",
        all :  "projectsWeb/"
    },
    projectsPython: {
        one : "projectsPython/",
        create : "projectsPython/",
        all :  "projectsPython/"
    },
    projectSubmission : {
        one : "projectSubmission/",
        create : "projectSubmission/",
        all :  "projectSubmission/",
        approved_count :  "approved-count/",
        approvedProjects: "approvedProjects/"
    },
    replays: {
        one : "replays/",
        create : "replays/",
        all :  "replays/"
    },
}