import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiChevronRight, FiChevronDown, FiCheckCircle } from 'react-icons/fi';
import { FaCat, FaMobileAlt, FaPython, FaRobot, FaLaptop} from 'react-icons/fa';
import { MdPlayLesson } from 'react-icons/md'
import {IoLogoGameControllerB} from 'react-icons/io';
import {MdAnimation} from 'react-icons/md';
import {TbWorldWww } from 'react-icons/tb';
import { RootState } from "../../store";
import { NavLink, useNavigate, useParams } from "react-router-dom";

import { BiLogOut as LogOut } from "react-icons/bi";
import { setCourseId, setLessonId } from "../../redux/global";
import { isApproved, isEnable } from "../../utils/commons.utils";
import { setAuthentication } from "../../redux/authentication";
import { setProfile } from "../../redux/profile";
import { ROUTES } from "../../routes/routerConfig";


function SideBar(): JSX.Element {

      //Use dispatch
      const dispatch = useDispatch();

         //Use navigate
      const history = useNavigate();

     
  const courses = useSelector((state: RootState) => state.entities.courses);
  const lessons = useSelector((state: RootState) => state.entities.lessons);
  const lessonId = useSelector((state: RootState) => state.global.lessonId);
  const courseId = useSelector((state: RootState) => state.global.courseId);
  const approvedProjects = useSelector((state: RootState) => state.entities.approvedProjects);
 

  

const [openMenu, setOpenMenu] = useState(0);
const [sidebarWidth, setSidebarWidth] = useState(56); // Initial width of the sidebar
const [currentCourse, setCurrentCourse] = useState("");
const [currentLesson, setCurrentLesson] = useState("");


const sidebarRef = useRef(null);


useEffect(() => {
 
  if(courseId && lessonId){
    setCurrentCourse(courseId)
    setCurrentLesson(lessonId)
  }
}, [courseId,lessonId]);

const handleMenuClick = (index: number) => {
 
  if (openMenu === index && currentCourse==="") {
    setOpenMenu(0);
  } else {
    setOpenMenu(index);
    const course = courses[index];
    dispatch(setCourseId(course?.id));
    dispatch(setLessonId(""));
  }
};


const getIcon = (index :number) => {
  switch (index) {
    case 0:
      return   <MdPlayLesson className="h-5 w-5" />;
      break;
    case 1:
      return   <FaCat className="h-5 w-5" />;
      break;
    case 2:
      return   <TbWorldWww className="h-5 w-5" />;
      break;
    case 3:
      return    <FaPython className="h-5 w-5" />;
      break;
    case 4:
      return   <FaMobileAlt className="h-5 w-5" />;
      break;
    case 5:
      return   <IoLogoGameControllerB className="h-5 w-5" />;
      break;
    case 6:
      return   <MdAnimation className="h-5 w-5" />;
      break;
    case 7:
      return   <FaRobot className="h-5 w-5" />;
      break;
    default:
      
  }
};

useEffect(() => {
  const sidebar = sidebarRef.current;
  const resizeObserver = new ResizeObserver(entries => {
    for (const entry of entries) {
      const { width } = entry.contentRect;
      setSidebarWidth(width)
    }
  });

  if (sidebar) {
    resizeObserver.observe(sidebar);
  }

  return () => {
    resizeObserver.disconnect();
  };
}, []);

const logOut = () => {
  //SET SESSION
  sessionStorage.setItem("isAuthenticated", "false");
  sessionStorage.setItem("token", "");
  sessionStorage.setItem("profile", "");

  //Update Authentication in Redux
  dispatch(setAuthentication({ isAuthenticated: false, token: "" }));
  dispatch(setProfile(null));

  //REDIRECT to login
  history(ROUTES.LOGIN);
};


return (
  <div ref={sidebarRef} className=" fixed flex flex-col top-14 left-0 w-14 hover:w-72 md:w-72 bg-primary h-full text-white transition-all duration-300 border-none z-10 sidebar">
  <div className="overflow-y-auto overflow-x-hidden flex flex-col justify-between flex-grow">
    <ul className="flex flex-col py-4 space-y-1">
      {courses.map((course, index) => (
      <><li key={index} onClick={() => handleMenuClick(index)} className={"transform hover:scale-105 transition duration-200 ease-in-out"}>
          <a href="#" className={`relative flex flex-row items-center h-11 focus:outline-none hover:bg-white hover:text-black  text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-blue-500 dark:hover:border-gray-800 pr-6 ${currentCourse === course.id ? "bg-secondary " : ""}`}
          >
            {isApproved(course.project) && <FiCheckCircle size={20} className="ml-2 text-green-500" />}
            <span className="inline-flex justify-center items-center ml-4">
              {getIcon(index)}
            </span>
            <span className="ml-2 text-base tracking-wide truncate">{course.name}</span>
            <span className="hidden md:block px-1 py-0.5 ml-auto text-xs font-medium tracking-wide rounded-full">
              {openMenu === index ? <FiChevronDown size={20} /> : <FiChevronRight size={20} />}
            </span>
          </a>
          {openMenu === index && sidebarWidth > 56 && (

            <ul className="ml-4 space-y-1">
              <li className="p-3 transform hover:scale-105 transition duration-200 ease-in-out">
                {course.lessons.map((id, index) => {
                  const lesson = lessons.find((lesson) => lesson.id === id);
                  const disabled = lesson ? !isEnable(lesson?.course) : true;
                  const linkClassNames = `relative flex flex-row items-center h-11 focus:outline-none hover:bg-white text-white-600 hover:text-black hover:border-white pr-6 ${currentLesson === lesson?.id ? 'bg-secondary' : ''} ${disabled ? 'opacity-50 pointer-events-none cursor-not-allowed' : ''}`;

                  return (
                    <NavLink
                      key={index}
                      to={`/lessons/${lesson?.id}`}
                      className={linkClassNames}
                    >
                      <span className="ml-2 text-sm tracking-wide truncate">{lesson?.title}</span>
                    </NavLink>
                  );
                })}
              </li>
            </ul>
          )}

        </li>
        </>
      ))}
      <li onClick={() => logOut()} className={"transform hover:scale-105 transition duration-200 ease-in-out mt-4"}>
          <a href="#" className={`relative flex flex-row items-center h-11 focus:outline-none hover:bg-white hover:text-black  text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-blue-500 dark:hover:border-gray-800 pr-6`}
          >
            <span className="inline-flex justify-center items-center ml-4">
            <LogOut className={"w-5 h-5"} />
            </span>
            <span className="ml-2 text-base tracking-wide truncate">Déconnexion</span>
          </a>
          </li>
    </ul>
    <p className="mb-14 px-5 py-3 hidden md:block text-center text-xs">Copyright @2023</p>
  </div>
</div>
)

}

export default SideBar;
