import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//Redux
import { setAuthentication } from "../../redux/authentication";
import { setProfile } from "../../redux/profile";


import { BiLogOut as LogOut } from "react-icons/bi";
import { ROUTES } from "../../routes/routerConfig";
import { RootState } from "../../store";
import { useEffect, useState } from "react";


const navigation = [
  { name: 'Tableaux de Bord', to: ROUTES.DASHBOARD},
  { name: 'QCM', to: ROUTES.QCM,},
  { name: 'Projets', to: ROUTES.PROJECTS},
  { name: 'Replay Coaching', to: ROUTES.REPLAYS},
  { name: 'Validation project', to: ROUTES.VALIDATE_PROJECT},
  
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function Header() {

   // Get current path
   const location = useLocation();

   //Use dispatch
   const dispatch = useDispatch();

   //Use navigate
   const history = useNavigate();

    //use selector
   const profile= useSelector((state: RootState) => state.profile);

   //use state
   const [isAdmin, setIsAdmin] = useState<boolean>(false);

   useEffect(() => { 
    setIsAdmin(profile.is_superuser)
  }, [profile]);

   const logOut = () => {
       //SET SESSION
       sessionStorage.setItem("isAuthenticated", "false");
       sessionStorage.setItem("token", "");
       sessionStorage.setItem("profile", "");

       //Update Authentication in Redux
       dispatch(setAuthentication({ isAuthenticated: false, token: "" }));
       dispatch(setProfile(null));

       //REDIRECT to login
       history(ROUTES.LOGIN);
   };
   const current=location.pathname;
   
   return (
    <div className="fixed w-full flex items-center justify-between h-14 text-white z-10">
  <div className="flex items-center justify-start md:justify-center pl-3 w-14 md:w-72 h-14 bg-primary border-none">
  <img className="w-7 h-7 md:w-10 md:h-10 mr-2 rounded-md overflow-hidden" src="/logo-white.png" alt="Daara Academy" />
  <NavLink to={ROUTES.DASHBOARD}>
    <span className="hidden md:block">DAARA ACADEMY</span>
  </NavLink>
</div>

    <div className="flex w-full justify-between items-center h-14 bg-primary header-right">
    
      <div className="flex w-full items-center w-full max-w-xl ml-4 md:ml-12 mr-4 p-2 shadow-sm">
     {/* Navigation links for the menu */}
     {navigation.map((item) => (
            // Conditionally render the "Validation project" NavLink if isAdmin is true
            (isAdmin || item.name !== 'Validation project') && (
              <NavLink
                key={item.name}
                to={item.to}
                className={classNames(
                  current === item.to ? 'bg-green-900 text-white' : 'text-white hover:bg-green-700 hover:text-white',
                  'rounded-md px-3 py-2 text-sm font-medium'
                )}
                aria-current={current === item.to ? 'page' : undefined}
              >
                {item.name}
              </NavLink>
            )
          ))}
      </div>
      <ul className="flex items-center">
        <li>
          <div className="block w-px h-6 mx-3 bg-white"></div>
        </li>
        <li>
          <button className="flex items-center mr-4 hover:underline hover:bg-theme1 p-1 md:p-2.5 rounded-lg text-xs md:text-sm"
                            onClick={logOut}
                        >
                            <span className="inline-flex mr-1">
                                <LogOut className={"w-5 h-5"} />
                            </span>
                            <span className="hidden md:block">Me déconnecter</span>
                        </button>
        </li>
      </ul>
    </div>
  </div>
   )
}
