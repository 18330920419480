//React libraries
import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

//Components
//import PageSpinner from "../components/Spinner/PageSpinner";
import PrivateRoute from "./PrivateRoute";
import PageSpinner from "../components/UI/PageSpinner";

//Pages
import Login from "../pages/auth/Login";
import Signup from "../pages/auth/Signup";
import ResetPassword from "../pages/auth/ResetPassword";
import ResetPasswordConfirm from "../pages/auth/ResetPasswordConfirm";
import Project from "../pages/projects";
import QCM from "../pages/qcm";
import CGV from "../pages/footer/CGV ";
import Confidentiality from "../pages/footer/confidentiality";
import Mentions from "../pages/footer/mentions";
import NotFoundPage from "../pages/NotFoundPage";

//Routes
import { ROUTES } from "./routerConfig";
import Spinner from "../components/UI/Spinner";



function Routing() {


    const Question = lazy(() => import("../pages/question/question"));
    const Editor = lazy(() => import("../pages/editor/Editor"));
    const Lesson = lazy(() => import("../pages/lesson/lesson"));
    const Dashboard = lazy(() => import("../pages/dashbord"));
    const Replays = lazy(() => import( "../pages/replays/replays"));
    const Replay = lazy(() => import( "../pages/replays/replay"));
    const ValidateProject = lazy(() => import( "../pages/validateProject"));
    
    return (
        <Suspense
            fallback={
                <>
                <div className="flex justify-center items-center h-screen">
                   <Spinner/>
                </div>
                </>
               
            }
        >
            <Routes>
                <Route path={ROUTES.HOME} element={<PrivateRoute />} > 
                    <Route path={ROUTES.HOME} element={<Dashboard />} />
                    <Route path={ROUTES.EDITOR} element={<Editor/>} />
                    <Route path={ROUTES.SHARE} element={<Editor/>} />
                    <Route path={ROUTES.LESSON} element={<Lesson/>} />
                    <Route path={ROUTES.QUESTIONS} element={<Question/>} />
                    <Route path={ROUTES.DASHBOARD} element={<Dashboard/>} />
                    <Route path={ROUTES.PROJECTS} element={<Project/>} />
                    <Route path={ROUTES.QCM} element={<QCM/>} />
                    <Route path={ROUTES.REPLAYS} element={<Replays/>} />
                    <Route path={ROUTES.REPLAY} element={<Replay/>} />
                    <Route path={ROUTES.VALIDATE_PROJECT} element={<ValidateProject/>} />
                </Route>
                <Route path={ROUTES.LOGIN} element={<Login />} />
                <Route path={ROUTES.SIGNUP} element={<Signup />} />
                <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
                <Route path={ROUTES.RESET_PASSWORD_CONFIRM} element={<ResetPasswordConfirm />} />
                <Route path={ROUTES.CGV} element={<CGV />} />
                <Route path={ROUTES.CONFIDENTIALITE} element={<Confidentiality />} />
                <Route path={ROUTES.MENTIONS} element={<Mentions/>} />
                 {/* Add the catch-all route for 404 page */}
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </Suspense>
    );
}

export default Routing;
