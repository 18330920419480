// React libraries
import { createSlice } from "@reduxjs/toolkit";
import { Course,Lesson, ProjectSubmission, Replay, User } from "../react-app-env";

interface EntitiesState {
 
    courses: Course[];
    lessons: Lesson[];
    replays: Replay[];
    approvedProjects : ProjectSubmission[];
    users: User [];
  
}

// Define the initial state using that type
const initialState: EntitiesState = {
       courses: [],
       lessons: [],
       replays: [],
       approvedProjects: [],
       users: [],
    
};

export const entitiesSlice = createSlice({
    name: "entities",
    initialState,
    reducers: {
        //TODO: view this TS warning & fix it
        addEntity: (state, action) => {
            state[action.payload.attribute as keyof EntitiesState].push(action.payload.data);
        },
        removeEntity: (state, action) => {
            // @ts-ignore
            state[action.payload.attribute as keyof EntitiesState] = state[
                action.payload.attribute as keyof EntitiesState
                // @ts-ignore
            ].filter((item: { id: any }) => item.id !== action.payload.data.id);
        },
        setEntity: (state, action) => {
            state[action.payload.attribute as keyof EntitiesState] = action.payload.data;
        },
        updateEntity: (state, action) => {
            // @ts-ignore
            state[action.payload.attribute as keyof EntitiesState] = state[action.payload.attribute as keyof EntitiesState].map((item) => {
                if (item.id === action.payload.data.id) {
                    item = action.payload.data;
                    return item;
                }
                return item;
            });
        },
    },
});

export const { addEntity, removeEntity, setEntity, updateEntity } = entitiesSlice.actions;

export default entitiesSlice.reducer;
