import { createSlice } from "@reduxjs/toolkit";

const initialState = sessionStorage.getItem("profile") ? JSON.parse(sessionStorage.getItem("profile") as string) : null;

export const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        setProfile: (state, action) => action.payload,
    },
});

export const { setProfile } = profileSlice.actions;

export default profileSlice.reducer;
